module.exports = [
  {
    locale: 'es',
    label: 'Español',
    default: true,
  },
  {
    locale: 'pt',
    label: 'Português',
  },
  {
    locale: 'en',
    label: 'English',
  },
];
